import { BaseQueryParams } from "../baseQuery";

export const rolesService = BaseQueryParams("rolesService", [
  "ROLES",
]).injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/profile/roles${page ? `?page=${page}` : ""}&per_page=${
          per_page ? `${per_page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["ROLES"],
    }),

    deleteRole: builder.mutation({
      query: (id) => ({
        url: `/admin/profile/role/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["ROLES"],
    }),

    createRole: builder.mutation({
      query: (body) => ({
        url: "/admin/profile/role",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["ROLES"],
    }),

    editRole: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/profile/role/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["ROLES"],
    }),

    getPermissions: builder.query({
      query: ({ page, per_page }) => ({
        url: `/admin/profile/permissions${page ? `?page=${page}` : ""}${
          per_page ? `&per_page=${per_page}` : ""
        }`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetRolesQuery,
  useDeleteRoleMutation,
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetPermissionsQuery,
} = rolesService;
