import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import Footer from "../../Footer";
import Header from "../../Header";

import { StyledMain, StyledContainer } from "./styles";

const LazyLayout = ({ offLayout }) => (
  <>
    {!offLayout && (
      <>
        <Header />

        <StyledContainer>
          <StyledMain>
            <Suspense fallback={<h1>Loading ...</h1>}>
              <Outlet />
            </Suspense>
          </StyledMain>

          <Footer />
        </StyledContainer>
      </>
    )}

    {offLayout && (
      <Suspense fallback={<h1>Loading ...</h1>}>
        <Outlet />
      </Suspense>
    )}
  </>
);

export default LazyLayout;
