import { BaseQueryParams } from "../baseQuery";

export const settingsService = BaseQueryParams("config").injectEndpoints({
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: () => ({
        url: "/config/public",
        method: "GET",
      }),
    }),

    moduleShopify: builder.mutation({
      query: ({ body, from = "", to = "" }) => ({
        url: `https://api.newapp.shopping-cart-migration.com/v1/wizard/modules/from-${from}-to-${to}/shopify`,
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["SURVEY"],
    }),
  }),
});

export const { useGetConfigQuery, useModuleShopifyMutation } = settingsService;
