import React, { useCallback } from "react";
import { useForm } from "react-hook-form";

import { ReactComponent as Featured } from "../../../../assets/images/Featured.svg";
import { useForgotPasswordMutation } from "../../../../store/authentication/authService";

import AuthForgotPasswordForm from "../AuthForgotPasswordForm";
import AuthForgotPasswordSuccess from "../AuthForgotPasswordSuccess";

import { Container, Wrapper, Title, Link } from "./styles";

const AuthForgotPassword = ({ changeTab }) => {
  const [forgotPassword, { isLoading, isSuccess }] =
    useForgotPasswordMutation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "" },
    mode: "all",
  });

  const goToSignIn = useCallback(() => changeTab("sign-in"), []);

  const onSubmit = async ({ email }) => {
    try {
      await forgotPassword({ email }).unwrap();
    } catch (error) {
      setError("email", {
        type: "custom",
        message:
          error?.data?.error?.details?.email[0] || error?.data?.error?.message,
      });
    }
  };

  return (
    <Container>
      <Wrapper>
        {isSuccess && <Featured />}

        <Title>Forgot password</Title>

        {isSuccess ? (
          <AuthForgotPasswordSuccess goToSignIn={goToSignIn} />
        ) : (
          <AuthForgotPasswordForm
            errors={errors}
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            isValid
            isLoading={isLoading}
          />
        )}

        {!isSuccess && <Link onClick={goToSignIn}>Sign In</Link>}
      </Wrapper>
    </Container>
  );
};

export default AuthForgotPassword;
