import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Intercom, { IntercomAPI } from "react-intercom";

import { ReactComponent as FundsIcon } from "../../../assets/images/FundsIcon.svg";
import { ReactComponent as ListIcon } from "../../../assets/images/ListIcon.svg";
import { ReactComponent as InvoiceIcon } from "../../../assets/images/InvoiceIcon.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/images/NotificationIcon.svg";
import { ReactComponent as HelpIcon } from "../../../assets/images/HelpIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/images/LogoutIcon.svg";
import { ReactComponent as PaymentIcon } from "../../../assets/images/PaymentIcon.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/images/ProfileIcon.svg";
import { useAuth } from "../../../hooks/useAuth";
import { useFormTo } from "../../../hooks/useFormTo";
import { useGtmEvent } from "../../../hooks/useGtmEvent";
import { useLogoutMutation } from "../../../store/authentication/authService";
import { setUserLogout } from "../../../store/profile/profileSlice";
import { AUTH_GTM_EVENTS } from "../../../constants/auth-gtm-events";

import ProgressProfile from "../ProgressProfile";
import DropdownLink from "../DropdownLink";

import { Container, Title, SubTitle, LogoutButton, Text } from "./styles";

const DropdownMenu = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prefix } = useFormTo();
  const { sendGtmEvent } = useGtmEvent();

  const [logout] = useLogoutMutation();

  const onLogout = async () => {
    try {
      await logout().unwrap();

      dispatch(setUserLogout());
      navigate(`${prefix}/`);
      sendGtmEvent(AUTH_GTM_EVENTS.logout);
    } catch (error) {
      navigate(`${prefix}/`);
    }
  };

  const handleClickButton = (type) => {
    IntercomAPI("show");
  };

  return [
    {
      key: "1",
      label: (
        <Container border={1}>
          <Title>{user?.username}</Title>

          <SubTitle>{user?.email}</SubTitle>
        </Container>
      ),
      disabled: true,
    },
    {
      key: "2",
      label: (
        <ProgressProfile
          percent={user?.profile_completeness}
          balance={user?.balance}
        />
      ),
      disabled: true,
    },
    {
      key: "3",
      label: (
        <DropdownLink
          to={`${prefix}/profile`}
          title="My Profile"
          icon={<ProfileIcon />}
        />
      ),
    },
    {
      key: "4",
      label: (
        <DropdownLink
          to={`${prefix}/profile#balance-history`}
          title="My Payments"
          icon={<PaymentIcon />}
        />
      ),
    },
    {
      key: "5",
      label: (
        <DropdownLink
          to={`${prefix}/profile#billing`}
          title="Add Funds"
          icon={<FundsIcon />}
          border={1}
        />
      ),
    },
    {
      key: "6",
      label: (
        <DropdownLink
          to={`${prefix}/migrations/list`}
          title="My Migrations List"
          icon={<ListIcon />}
        />
      ),
    },
    {
      key: "7",
      label: (
        <DropdownLink
          to={`${prefix}/profile#generate-invoice`}
          title="Generate Invoice"
          icon={<InvoiceIcon />}
          border={1}
        />
      ),
    },
    // {
    //   key: "8",
    //   label: (
    //     <DropdownLink
    //       to={`${prefix}/`}
    //       title="Notifications & Alerts"
    //       icon={<NotificationIcon />}
    //     />
    //   ),
    // },
    {
      key: "9",
      label: (
        <LogoutButton onClick={handleClickButton}>
          <HelpIcon />

          <Text>Help & Support</Text>
        </LogoutButton>
        //   <DropdownLink
        //     to={`${IntercomAPI("show")}`}
        //     title="Help & Support"
        //     icon={<HelpIcon />}
        //   />
      ),
    },
    {
      key: "10",
      label: (
        <DropdownLink
          to={`${prefix}/profile#ticket`}
          title="Quick Create Ticket"
          icon={<PaymentIcon />}
          border={1}
        />
      ),
    },
    {
      key: "11",
      label: (
        <LogoutButton onClick={onLogout}>
          <LogoutIcon />

          <Text>Logout</Text>
        </LogoutButton>
      ),
    },
  ];
};

export default DropdownMenu;
