import { InputsRules } from "../../../constants/auth";

const BUTTONS_ACTIONS = {
  EDIT: {
    type: "edit",
    title: "Save",
    isIcon: false,
  },
  DELETE: {
    type: "delete",
    title: "Return to default Api",
    isIcon: false,
  },
};

const BUTTONS_THEME = {
  EDIT: {
    type: "edit",
    title: "Change theme",
    isIcon: false,
  },
};

export const actionsForm = () => [BUTTONS_ACTIONS.EDIT, BUTTONS_ACTIONS.DELETE];
export const actionsTheme = () => [BUTTONS_THEME.EDIT];

export const inputRules = {
  host_api: {
    required: InputsRules.required,
    pattern: InputsRules.patternUrl,
  },
};
