import {
  REQUIRED_MESSAGE,
  PATTERN_PASSWORD_MESSAGE,
  PATTERN_EMAIL_MESSAGE,
  PATTERN_URL_MESSAGE,
} from "./input-errors-message";
import {
  PATTERN_PASSWORD,
  PATTERN_EMAIL,
  PATTERN_URL,
  PATTERN_STORE_URL,
} from "./patterns";

export const InputsRules = {
  required: {
    value: true,
    message: REQUIRED_MESSAGE,
  },
  patternPassword: {
    value: PATTERN_PASSWORD,
    message: PATTERN_PASSWORD_MESSAGE,
  },
  patternEmail: {
    value: PATTERN_EMAIL,
    message: PATTERN_EMAIL_MESSAGE,
  },
  patternUrl: {
    value: PATTERN_URL,
    message: PATTERN_URL_MESSAGE,
  },
  patternStoreUrl: {
    value: PATTERN_STORE_URL,
    message: PATTERN_URL_MESSAGE,
  },
};

export const LOGIN_INPUT = [
  {
    label: "Email",
    name: "email",
    rules: {
      required: InputsRules.required,
      pattern: InputsRules.patternEmail,
    },
    key: 1,
  },
  {
    label: "Password",
    name: "password",
    rules: {
      required: InputsRules.required,
      pattern: InputsRules.patternPassword,
    },
    key: 2,
  },
];

export const LOGIN_VALUE = {
  username: "",
  password: "",
  save: false,
};

export const PASSWORD_RULES = {
  required: InputsRules.required,
  pattern: InputsRules.patternPassword,
  maxLength: InputsRules.maxLengthPassword,
};
