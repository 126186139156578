import React, { memo } from "react";
import { StyledTooltip } from "./styles";

const Tooltip = ({
  children,
  color = "#ffffff",
  title,
  border = 2,
  ...rest
}) => (
  <StyledTooltip
    color={color}
    title={title}
    overlayStyle={{ borderRadius: `${border}px` }}
    overlayInnerStyle={{ borderRadius: `${border}px` }}
    {...rest}
  >
    {children}
  </StyledTooltip>
);

export default memo(Tooltip);
