import { BaseQueryParams } from "../baseQuery"

export const entitiesPacksService = BaseQueryParams("entitiesPacksService", ['ENTITIES_PACKS']).injectEndpoints({
  endpoints: (builder) => ({
    getEntitiesPacks: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/entities-packs${page ? `?page=${page}`: ""}&config=1`,
        method: "GET",
        params: filters,
      }),
      providesTags: () => ["ENTITIES_PACKS"],
    }),

    editEntitiesPack: builder.mutation({
      query: (body) => ({
        url: `/admin/entities-packs`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ['ENTITIES_PACKS'],
    }),

    editDefaultEntitiesPack: builder.mutation({
      query: (body) => ({
        url: `/admin/entities-packs/default`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ['ENTITIES_PACKS'],
    }),
  })
})

export const {
  useGetEntitiesPacksQuery,
  useEditEntitiesPackMutation,
  useEditDefaultEntitiesPackMutation,
} = entitiesPacksService
