export const WIZARD_ROOT_PATH = "wizard";

export const WIZARD_STEP_PATH = {
  ACCOUNT_SELECTION: "account-selection",
  SELECT_SOURCE: "select-source",
  SOURCE_STORE: "source-store",
  SOURCE_CONNECTION: "source-connection",
  SELECT_TARGET: "select-target",
  TARGET_STORE: "target-store",
  TARGET_CONNECTION: "target-connection",
  ENTITIES_SELECTION: "entities-selection",
  OPTIONS_SELECTION: "options-selection",
  MAPPING_SETUP: "mappings-setup",
  DEMO_MIGRATION: "demo-migration",
  FINISH: "finish",
};
