import { styled } from "styled-components";

export const StyledMain = styled.main`
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1710px) {
    max-width: calc(100% - 226px);
  }

  @media (max-width: 1580px) {
    max-width: calc(100% - 50px);
  }

  @media (max-width: 1460px) {
    zoom: 0.98;
  }

  @media (max-width: 1420px) {
    zoom: 0.95;
  }

  @media (max-width: 1380px) {
    zoom: 0.90;
  }

  @media (max-width: 1303px) {
    zoom: 1;
    max-width: 1200px;
  }
`;

export const StyledContainer = styled.main`
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 109px - 32px);
  overflow-y: auto;

  @media (max-width: 900px) {
    height: calc(100% - 120px);
    padding: 0 20px;
  }

  @media (max-width: 650px) {
    padding: 0 10px;
  }
`;
