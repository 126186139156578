import { BaseQueryParams } from "../baseQuery";

export const estimatesService = BaseQueryParams(
  "estimatesService",
).injectEndpoints({
  endpoints: (builder) => ({
    getEstimates: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/users/estimates${page ? `?page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),
    }),
  }),
});

export const { useGetEstimatesQuery } = estimatesService;
