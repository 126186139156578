import { BaseQueryParams } from "../baseQuery";

export const reportsService = BaseQueryParams("reportsService", [
  "ADMIN_REPORTS",
  "DEBUG_STAT",
]).injectEndpoints({
  endpoints: (builder) => ({
    getReportService: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/service?${page ? `page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["ADMIN_ACCOUNTS"],
    }),

    getServiceCharts: builder.query({
      query: ({ body }) => ({
        url: `/admin/reports/service/chart`,
        method: "GET",
        params: body,
      }),
    }),

    getMigrationsLogs: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/migrations/logs?${page ? `page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getCouponsList: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/migrations/coupons?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getWizardStats: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/migrations/wizard-statistics?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getReportRatesTotal: builder.query({
      query: ({ filters }) => ({
        url: `/admin/reports/migrations/rates/totals`,
        method: "GET",
        params: filters,
      }),
    }),

    getReportRates: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/migrations/rates?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getReportProxies: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/migrations/proxies?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getReportRemigrations: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/migrations/remigrations-statistics?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getReportStepsFunnel: builder.query({
      query: ({ filters }) => ({
        url: `/admin/reports/migrations/steps-funnel`,
        method: "GET",
        params: filters,
      }),
    }),

    getReportTicketsSurvey: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/survey-tickets?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getReportDebugStatistics: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/migrations/debug-statistics?${
          page ? `page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["DEBUG_STAT"],
    }),

    deleteDebugStat: builder.mutation({
      query: (id) => ({
        url: `/admin/reports/migrations/debug-statistics/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["DEBUG_STAT"],
    }),
  }),
});

export const {
  useGetReportServiceQuery,
  useGetServiceChartsQuery,
  useGetMigrationsLogsQuery,
  useGetCouponsListQuery,
  useGetReportRatesQuery,
  useGetReportRatesTotalQuery,
  useGetWizardStatsQuery,
  useGetReportProxiesQuery,
  useGetReportRemigrationsQuery,
  useGetReportStepsFunnelQuery,
  useGetReportTicketsSurveyQuery,
  useGetReportDebugStatisticsQuery,
  useDeleteDebugStatMutation,
} = reportsService;
