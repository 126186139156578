import React, { memo } from "react";

import TopSection from "./TopSection";
import TopMessage from "./TopMessage";
import BottomSection from "./BottomSection";
import { useAdminPermission } from "../../hooks/useAdminPermission";
import { useAuth } from "../../hooks/useAuth";

import { Container, Wrapper, BottomWrapper } from "./styles";

const Header = () => {
  const { user } = useAuth();
  const isAdmin = useAdminPermission();

  return (
    <>
      <TopMessage />
      <Container $height={user?.id && !isAdmin}>
        <Wrapper direction="column">
          <TopSection />

          <BottomWrapper>
            <BottomSection />
          </BottomWrapper>
        </Wrapper>
      </Container>
    </>
  );
};
export default memo(Header);
