import React, { useState, useRef, useEffect, memo } from "react";
import OauthPopup from "react-oauth-popup";

import { ReactComponent as SocialFIcon } from "../../../../assets/images/SocialFIcon.svg";
import { ReactComponent as SocialGIcon } from "../../../../assets/images/SocialGIcon.svg";
import {
  useSocialLoginMutation,
  useSocialLoginCallbackMutation,
} from "../../../../store/authentication/authService";

import { Container, SocialButton } from "./styles";

const AuthSocialForm = () => {
  const [url, setUrl] = useState("");
  const [socialCode, setSocialCode] = useState(null);
  const [social, setSocial] = useState();
  const refBtnFaceBook = useRef(null);

  const [login, { isLoading }] = useSocialLoginMutation();
  const [loginCallback] = useSocialLoginCallbackMutation();

  const onClose = () => {
    setUrl("");
    setSocial("");
  };

  const sendCode = async (code) => {
    console.log("Received code:", code);
    onClose();
    setSocialCode(code);
    try {
      await loginCallback({
        social,
        code,
      }).unwrap();
      console.log("Completed");
    } catch (error) {
      onClose();
    }
  };

  const loginFacebook = async () => {
    try {
      setSocial("facebook");
      const res = await login({ social: "facebook" }).unwrap();

      if (res?.payload?.redirect_url) {
        console.log("Redirecting to Facebook:", res?.payload?.redirect_url);
        setUrl(`${res?.payload?.redirect_url}&approval_prompt=force`);
      }
    } catch (error) {
      console.error("Facebook login failed:", error);
    }
  };

  const loginGoogle = async () => {
    try {
      setSocial("google");
      const res = await login({ social: "google" }).unwrap();

      if (res?.payload?.redirect_url) {
        console.log("Redirecting to Google:", res?.payload?.redirect_url);
        setUrl(`${res?.payload?.redirect_url}&approval_prompt=force`);
      }
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };

  useEffect(() => {
    if (url) {
      console.log("Opening OAuth popup...");
      refBtnFaceBook?.current?.click();
    }
  }, [url]);

  useEffect(() => {
    if (socialCode) {
      onClose();
      console.log('socialCode Close Popup')
    }
  }, [socialCode]);

  return (
    <Container>
      <OauthPopup
        url={url}
        onCode={sendCode}
        onClose={onClose}
        width={500}
        height={700}
        title="Authentication"
      >
        <div ref={refBtnFaceBook} />
      </OauthPopup>

      <SocialButton
        onClick={loginFacebook}
        loading={isLoading && social === "facebook"}
        icon={<SocialFIcon />}
        color="#1877F2"
      >
        Sign in with Facebook
      </SocialButton>

      <SocialButton
        onClick={loginGoogle}
        loading={isLoading && social === "google"}
        icon={<SocialGIcon />}
        color="#E22C2A"
      >
        Sign in with Google
      </SocialButton>
    </Container>
  );
};

export default memo(AuthSocialForm);
