import { BaseQueryParams } from "../baseQuery";

export const balanceService = BaseQueryParams("balanceService", [
  "ADMIN_BALANCE",
]).injectEndpoints({
  endpoints: (builder) => ({
    getBalanceHistory: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/reports/balance-history${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
      providesTags: () => ["ADMIN_BALANCE"],
    }),

    editBalanceHistory: builder.mutation({
      query: (id) => ({
        url: `/admin/reports/balance-history/${id}`,
        method: "PUT",
      }),

      invalidatesTags: () => ["ADMIN_BALANCE"],
    }),
  }),
});

export const { useGetBalanceHistoryQuery, useEditBalanceHistoryMutation } =
  balanceService;
