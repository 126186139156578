import { BaseQueryParams } from "../baseQuery";

export const wizardLogsService = BaseQueryParams("wizardLogsService", [
  "WizardLogs",
]).injectEndpoints({
  endpoints: (builder) => ({
    getWizardLogs: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/logs/wizard${page ? `?page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["WizardLogs"],
    }),

    getSetupedLogs: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/developers/migrations/setuped${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["WizardLogs"],
    }),

    getUnsetupedLogs: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/developers/migrations/unsetuped${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["WizardLogs"],
    }),

    getStepsLogs: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/developers/wizard-logs/steps${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["WizardLogs"],
    }),
  }),
});

export const {
  useGetWizardLogsQuery,
  useGetSetupedLogsQuery,
  useGetUnsetupedLogsQuery,
  useGetStepsLogsQuery,
} = wizardLogsService;
