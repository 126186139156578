export const PERMISSIONS = {
  SUPER_ADMIN: "*",
  ADMIN_ACCESS: "admin_access",
  MANAGE_PERMISSIONS: "manage_permissions",
  MANAGE_ROLES: "manage_roles",
  WIZARD_ACCOUNT_SELECTION: "wizard_account_selection",
  ADMIN_READ_LOGS: "read_logs",
  PARTNER: "has_sub_accounts",
  EDIT_WIZARD_STEPS: "can_edit_migration_steps",
};

export const ADMIN_PERMISSIONS = [
  PERMISSIONS.SUPER_ADMIN,
  PERMISSIONS.ADMIN_ACCESS,
  PERMISSIONS.ADMIN_READ_LOGS,
];
export const MANAGE_PERMISSIONS = [
  PERMISSIONS.MANAGE_PERMISSIONS,
  PERMISSIONS.MANAGE_ROLES,
];
export const WIZARD_PERMISSIONS = [
  ...ADMIN_PERMISSIONS,
  ...MANAGE_PERMISSIONS,
  PERMISSIONS.WIZARD_ACCOUNT_SELECTION,
];
export const EDIT_WIZARD_PERMISSIONS = [
  PERMISSIONS.SUPER_ADMIN,
  PERMISSIONS.EDIT_WIZARD_STEPS,
];
export const PARTNER_PERMISSIONS = [PERMISSIONS.PARTNER];
