import { createSlice } from "@reduxjs/toolkit";
import { profileService } from "./profileService";

const initialState = {
  abilities: [],
  user: null,
  isLogin: true,
  isLoginCheck: false,
  invoice: {},
  openModal: false,
  paymentUrl: "",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,

  reducers: {
    setUserLogout: (state) => {
      state.user = null;
      state.abilities = [];
    },

    setInvoice: (state, { payload }) => {
      state.invoice = payload;
    },

    setOpenModal: (state, { payload }) => {
      state.openModal = payload;
    },

    setPaymentUrl: (state, { payload }) => {
      state.paymentUrl = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      profileService.endpoints.createNewFund.matchFulfilled,
      (state) => {
        state.openModal = false;
      },
    );

    builder.addMatcher(
      profileService.endpoints.createNewFund.matchRejected,
      (state) => {
        state.openModal = false;
      },
    );
  },
});

export const { setUserLogout, setInvoice, setOpenModal, setPaymentUrl } =
  profileSlice.actions;

export const selectBillingModal = (state) => state.profile.openModal;
export const selectBillingUrl = (state) => state.profile.paymentUrl;

export default profileSlice.reducer;
