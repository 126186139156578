import { BaseQueryParams } from "../baseQuery";

export const configMigrationBlockService = BaseQueryParams(
  "configMigrationBlockService",
  ["CONFIG_MIGRATION_BLOCK"],
).injectEndpoints({
  endpoints: (builder) => ({
    getBlocksList: builder.query({
      query: ({ page, per_page, filters }) => ({
        url: `/admin/configurations/migration-blocks${
          page ? `?page=${page}` : ""
        }${per_page ? `&per_page=${per_page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),
      providesTags: () => ["CONFIG_MIGRATION_BLOCK"],
    }),

    createBlock: builder.mutation({
      query: (body) => ({
        url: "/admin/configurations/migration-blocks",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["CONFIG_MIGRATION_BLOCK"],
    }),

    editBlock: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/configurations/migration-blocks/${id}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["CONFIG_MIGRATION_BLOCK"],
    }),

    deleteBlock: builder.mutation({
      query: (id) => ({
        url: `/admin/configurations/migration-blocks/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["CONFIG_MIGRATION_BLOCK"],
    }),
  }),
});

export const {
  useGetBlocksListQuery,
  useEditBlockMutation,
  useCreateBlockMutation,
  useDeleteBlockMutation,
} = configMigrationBlockService;
