import React, { useState, memo, useMemo, useCallback } from "react";

import AuthLogin from "../AuthLogin";
import AuthRegistration from "../AuthRegistration";
import AuthForgotPassword from "../AuthForgotPassword";

import { Container } from "./styles";

const AuthForm = () => {
  const [activeTab, setActiveTab] = useState("sign-in");

  const changeTab = useCallback((name) => setActiveTab(name), []);

  const tabs = useMemo(
    () => ({
      "sign-in": () => <AuthLogin changeTab={changeTab} />,
      "sign-up": () => <AuthRegistration changeTab={changeTab} />,
      "forgot-password": () => <AuthForgotPassword changeTab={changeTab} />,
    }),
    [changeTab],
  );

  const tab = useMemo(() => tabs?.[activeTab]?.(), [activeTab, tabs]);

  return <Container>{tab}</Container>;
};

export default memo(AuthForm);
