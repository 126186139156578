import { BaseQueryParams } from "../baseQuery";

export const cartPreviewService = BaseQueryParams("cartPreviewService", [
  "STORES",
  "PREPARED_STORES",
  "CONFIG_LIST",
]).injectEndpoints({
  endpoints: (builder) => ({
    getStoresList: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/cart-preview/stores${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["STORES"],
    }),

    deleteStore: builder.mutation({
      query: (id) => ({
        url: `/admin/cart-preview/store/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["STORES"],
    }),

    getPreparedStoresList: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/cart-preview/prepared-stores${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["PREPARED_STORES"],
    }),

    createPreparedStore: builder.mutation({
      query: (body) => ({
        url: "/admin/cart-preview/prepared-store",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["PREPARED_STORES"],
    }),

    getAccountUrl: builder.query({
      query: ({ url }) => ({
        url: `/admin/cart-preview/store-account`,
        method: "GET",
        params: url,
      }),
    }),

    getConfigList: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/cart-preview/configurations${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["CONFIG_LIST"],
    }),

    editConfig: builder.mutation({
      query: (body) => ({
        url: `/admin/cart-preview/configuration`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["CONFIG_LIST"],
    }),

    getLogs: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/cart-preview/logs${page ? `?page=${page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),
    }),

    getReportsList: builder.query({
      query: ({ page, filters }) => ({
        url: `/admin/cart-preview/reports${
          page ? `?page=${page}` : ""
        }&config=1`,
        method: "GET",
        params: filters,
      }),
    }),
  }),
});

export const {
  useGetStoresListQuery,
  useDeleteStoreMutation,
  useGetPreparedStoresListQuery,
  useCreatePreparedStoreMutation,
  useGetAccountUrlQuery,
  useGetConfigListQuery,
  useEditConfigMutation,
  useGetLogsQuery,
  useGetReportsListQuery,
} = cartPreviewService;
