import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {IntercomAPI} from "react-intercom";

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  const baseUrl = localStorage.getItem("host_api") || process.env.REACT_APP_HOST_API;

  const customBaseQuery = fetchBaseQuery({
    baseUrl,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const { access_token } = getState().auth;

      if (access_token) {
        headers.set("Authorization", `Bearer ${access_token}`);
      }

      return headers;
    },
  });

  const result = await customBaseQuery(args, api, extraOptions);

  if (!result.meta?.response || result.meta.response.status >= 500) {
    IntercomAPI("show");
    alert('Connection timed out, please contact technical support for more details.');
  }

  return result;
};

export const BaseQueryParams = (reducerPath, tags) => {
  const baseQuery = createApi({
    baseQuery: baseQueryWithReAuth,
    endpoints: () => ({}),
    reducerPath,
    tagTypes: tags?.length ? tags : [],
  });

  return baseQuery;
};
